.image-flair-background{
    background:rgb(46, 56, 89);
    margin:0px;
    color:#ffffff;
    height:100vmin;
    display:grid;
    place-items:center;
}

.middle{
display:grid;
place-items:center;
text-align: left;
padding:3vmin;}

.card-groups,
.card-group,
 card{
    aspect-ratio: 5 / 7;
}

.card-groups,
.card-group,
 .big-card{
    width: 30vmin;
}

.card-group {
    position:absolute;
    transition:transform 400ms ease;
}


.card{    
    aspect-ratio: 5 / 7;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    transition: transform 800ms cubic-bezier(.05,.43,.25,.95);
}

.big-card{
    width: 30vmin;
    border-radius: 5vmin;

}

.small-card{
    width: 12vmin;
    border-radius: 2vmin;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

}


.big-card:nth-child(2){
    background-image: url('/backend/images/0e8dab63-c7a9-4315-acc6-d5d1c60b99ce-1683449567402.jpg');
    background-size: 30vmin;
    transform: translateX(-10%) rotate(-1deg);
}
.big-card:nth-child(4){
    background-size: 30vmin;
    background-image:url('/backend/images/7801a432-cd6a-4906-9c15-c2e87da368cf-1690589642156.jpg');

    transform: rotate(2deg);
}
.big-card:nth-child(6){
    background-image:url('/backend/images/815c8352-2331-4d31-ab74-ba323bd44c13-1684924317507.jpg');
    background-size: 30vmin;

    transform: translateX(-6%) rotate(-3deg);
}
.big-card:nth-child(8){
    background-image: url('/backend/images/962472a7-0d39-4ac3-acb9-638485a0c128-1683448775768.jpg');
    background-size: 30vmin;
    transform: translateX(10%, 3%) rotate(5deg);
}


  
.card-group:hover > .big-card:nth-child(2){
    transform: translate(-75%, 16%) rotate(-24deg);
}
.card-group:hover > .big-card:nth-child(4){
    transform: translate(-25%, 8%) rotate(-8deg);
}
.card-group:hover > .big-card:nth-child(6){
    transform: translate(25%, 8%) rotate(8deg);
}
.card-group:hover > .big-card:nth-child(8){
    transform: translate(75%, 16%) rotate(24deg);
}


.card-group:hover > .small-card:nth-child(1){
    background-image:url('/backend/images/991f8d28-5e1b-43a6-b307-9a90e4b4a061-1684829469628.jpg');
    background-size: 12vmin;
    transform: translate(200%, -160%) rotate(-15deg);
}
.card-group:hover > .small-card:nth-child(3){
    background-image:url('/backend/images/0ed50ffd-a196-4c28-9d1e-9e3c30579c76-1684827076823.jpg');
    background-size: 12vmin;
    transform: translate(160%, 170%) rotate(15deg);
}
.card-group:hover > .small-card:nth-child(5){
    background-image:url('/backend/images/e721c5b6-274d-4932-b303-83b7ba7d6649-1684825646425.jpg');
    background-size: 12vmin;
    transform: translate(-200%, -170%) rotate(15deg);
}
.card-group:hover > .small-card:nth-child(7){
    background-image:url('/backend/images/da8b411d-6dc7-45c4-a2c1-e2a703f42b57-1682155299052.jpg');
    background-size: 12vmin;
    transform: translate(-280%, 140%) rotate(-15deg);
}




